<template>

    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <!--h6 class="modal-title headertitle" id="staticBackdropLabel2">{{titlemodal}} </h6-->

                    <div class="flex p-4 items-center justify-between border-b dark:border-defaultborder/10">

                        <div class="flex items-center">
                            <div class="hs-dropdown ti-dropdown me-1">
                                <button class="ti-btn ti-btn-sm ti-btn-primary" aria-label="button" type="button" aria-expanded="false">
                                    <i class="ri-more-2-fill"></i>
                                </button>
                                <ul class="hs-dropdown-menu ti-dropdown-menu hidden" style="">
                                    <li><a @click="btncustomlib"  class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium" href="javascript:void(0);">
                                        Personnaliser les libellés
                                    </a></li>

                                </ul>
                            </div>
                            <button aria-label="button" type="button" id="file-close-btn" class="ti-btn ti-btn-icon ti-btn-sm ti-btn-danger xl:hidden block">
                                <i class="ri-close-fill"></i>
                            </button>
                        </div>


                        <div>
                            <h6 class="modal-title headertitle"> &nbsp;{{titlemodal}}</h6>
                        </div>

                    </div>











                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between " style="display: block">



                            <div class="grid grid-cols-12  gap-x-6">


                                <div class=" xl:col-span-8  col-span-12 mt-4 mb-4">
                                    <input v-model="searchword" class="form-control form-control-lg w-full custom-placeholder" type="text"
                                           placeholder="Rechercher"
                                           aria-label=".form-control-lg example">
                                </div>

                                <div class="xl:col-span-3  col-span-12 mt-4 mb-4"></div>


                                <div class="xl:col-span-1  col-span-12 mt-4 mb-4">
                                    <button v-if="accesnewbtn" @click="btnajouter" class="ti-btn ti-btn-primary-full !py-1 !px-2 !text-[0.75rem] w-full">
                                        <i class="ri-add-line  align-middle"></i>Ajouter
                                    </button>
                                </div>



                                <div class=" xl:col-span-6  col-span-12 mt-4 mb-4">

                                    <select v-model="DefaultperPage" style=" display: inline-block; margin: 0 5px; padding: 2px 25px;
                                            border: 1px solid #aaa; border-radius: 3px;" class="tabulator-page-size" aria-label="Page Size" title="Affichage">
                                        <option value="4">4</option>
                                        <option value="10">10</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>

                                    </select>
                                </div>


                                <!--div class=" xl:col-span-6  col-span-12 mt-4 mb-4">

                                    <select v-model="searchtrierletableau"   style=" display: inline-block; margin: 0 5px; padding: 2px 25px;
                                            border: 1px solid #aaa; border-radius: 3px;" class="tabulator-page-size" aria-label="Page Size" title="Affichage">
                                        <option value="">Trier le tableau suivant / Annuler le trie</option>
                                        <option value="1">Absences / Congés annuler</option>
                                        <option value="0">Absences / Congés en cours</option>
                                    </select>
                                </div-->

                            </div>


                            <div class="w-full flex grid-cols-2">

                                <div class="box-title mb-2 sm:mb-0"></div>

                                <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic example">
                                    <button type="button" @click="indexbutton='1d';load()" :class="indexbutton==='1d'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">1D</button>
                                    <button type="button" @click="indexbutton='1W';load()" :class="indexbutton==='1W'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3 ">1W</button>
                                    <button type="button" @click="indexbutton='1M';load()" :class="indexbutton==='1M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3 ">1M</button>
                                    <button type="button" @click="indexbutton='3M';load()" :class="indexbutton==='3M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  !rounded-s-none">3M</button>
                               </div>
                            </div>






                        </div>


                        <div class="box-body !p-0 mybodyheight300px">
                            <div class="table-responsive">

                                <!-- component -->

                                <div class="flex flex-col h-screen">
                                    <div class="flex-grow overflow-auto">




                                        <table class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">
                                            <thead>
                                            <tr>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° DOCUMENT</th>
                                                <!--th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">DATE</th-->
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">MAGASIN/DEPÔT/ENTREPÔT</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" v-if="typedoc==='mvt_transfert'">VERS MAGASIN/DEPÔT/ENTREPÔT</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">OBSERVATION</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°BON</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">CREER LE</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">CREER PAR</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Action</th>

                                            </tr>
                                            </thead>
                                            <tbody>

                                            <template v-if="filterdatatable.length">

                                                <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in filterdatatable" :key="index">

                                                    <td data-label="N°  : ">{{index+1}}</td>
                                                    <td  data-label="N° DOCUMENT  : "   >{{ datavalue.numdoc }}</td>
                                                    <!--td  data-label="DATE  : "   >{{ datavalue.datedoc }}</td-->
                                                    <td  data-label="MAGASIN/DEPÔT/ENTREPÔT  : "   >{{ datavalue.magname }}</td>
                                                    <td v-if="typedoc==='mvt_transfert'"  data-label="VERS MAGASIN/DEPÔT/ENTREPÔT  : "   >{{ datavalue.vmagname }}</td>
                                                    <td  data-label="OBSERVATION  : "   >{{ datavalue.mobservations }}</td>
                                                    <td  data-label="N°BON  : "   >{{ datavalue.numbon }}</td>
                                                    <td  data-label="CREER LE  : "   >{{ datavalue.creerler }}</td>
                                                    <td  data-label="CREER PAR  : "   >{{ datavalue.saisiepar }}</td>

                                                     <td  data-label="Action  : "   >


                                                        <div class="ti-btn-group">
                                                            <div class="hs-dropdown ti-dropdown">
                                                                <button class="ti-btn ti-btn-info-full ti-dropdown-toggle" type="button"
                                                                        id="dropdownMenuButton1"
                                                                        aria-expanded="false">
                                                                    Action<i
                                                                        class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                                                </button>
                                                                <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                                                    aria-labelledby="dropdownMenuButton1">


                                                                    <li >
                                                                        <button style="width: 100%!important;" @click="btnopen(datavalue)"  aria-label="anchor"
                                                                                class=" ti-btn ti-btn-sm ">
                                                                            <i class="ri-folder-open-fill"></i> Ouvrir
                                                                        </button>
                                                                    </li>

                                                                    <li v-if="datavalue.nbp>0">
                                                                        <button style="width: 100%!important;" @click="btnimpression(datavalue)"  aria-label="anchor"
                                                                                class=" ti-btn ti-btn-sm ">
                                                                            <i class="ri-printer-fill"></i> Imprimer
                                                                        </button>
                                                                    </li>
                                                                    <li v-if="datavalue.nbp>0">
                                                                        <button style="width: 100%!important;" @click="btntransformationdoc(datavalue)"  aria-label="anchor"
                                                                                class=" ti-btn ti-btn-sm ">
                                                                            <i class="ri-exchange-fill"></i> Transformer
                                                                        </button>
                                                                    </li>
                                                                    <li v-if=" datavalue.nuse===0 &&datavalue.nbp>0 && (typedoc==='F' || typedoc==='FA')">
                                                                        <button style="width: 100%!important;" @click="btnnormalisationdelafacture(datavalue)"  aria-label="anchor"
                                                                                class=" ti-btn ti-btn-sm ">
                                                                            <i ></i> Normaliser
                                                                        </button>
                                                                    </li>


                                                                    <li v-if="datavalue.nuse===0 && accesupdatebtn">
                                                                        <button style="width: 100%!important;" @click="btnmodifier(datavalue)"  aria-label="anchor"  class=" ti-btn
                                                               ti-btn-sm ">
                                                                            <i class="ri-pen-nib-line"></i> Modifier
                                                                        </button>
                                                                    </li>


                                                                    <li v-if="datavalue.nuse===0 && datavalue.nbp===0 && accesdeletebtn">
                                                                        <button style="width: 100%!important;" @click="btnsuppression(datavalue)"  aria-label="anchor"  class=" ti-btn
                                                               ti-btn-sm ">
                                                                            <i class="ri-delete-bin-line"></i> Supprimer
                                                                        </button>
                                                                    </li>


                                                                </ul>
                                                            </div>
                                                        </div>




                                                    </td>

                                                </tr>



                                            </template>


                                            <template v-else>
                                                <tr >
                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                </tr>

                                            </template>



                                            </tbody>
                                        </table>


                                    </div>
                                </div>

                            </div>
                        </div>





                        <div class="flex flex-col box-footer border-t-0 ">
                            <div class="flex items-center flex-wrap overflow-auto">
                                <div class="mb-2 sm:mb-0">

                                </div>
                                <div class="ms-auto">


                                    <nav aria-label="Page navigation">
                                        <ul class="ti-pagination mb-0">
                                            <li class="page-item">
                                                <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Previous</button>
                                            </li>
                                            <li class="page-item">
                                                <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                        :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                    {{ pageNumber }}
                                                </button>
                                            </li>
                                            <li class="page-item">
                                                <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                            </li>
                                        </ul>
                                    </nav>





                                </div>
                            </div>
                        </div>
                    </div>






                </div>



                <div class="ti-modal-footer">


                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <modaltransformation ref="modaltransformation"   @elementSelectionnetransformation="handleElementSelectedtransformation" ></modaltransformation>
    <modalopenform ref="modalopenform"  ></modalopenform>
    <modalform ref="modalform"  @closed="load"></modalform>
    <modalimpressiondoc ref="modalimpressiondoc"  ></modalimpressiondoc>
    <modalformsuppression @closed="load"  ref="modalformsuppression"></modalformsuppression>
    <modalcustomlib ref="modalcustomlib" @elementSelectionnecustomlib="handleElementSelectedcustomlib" ></modalcustomlib>

    <normalisationdelafacture @closed="load" ref="normalisationdelafacture"  ></normalisationdelafacture>






    <achat_bcd_etat0 ref="achat_bcd_etat0"  ></achat_bcd_etat0>
    <achat_bcd_etat1 ref="achat_bcd_etat1"  ></achat_bcd_etat1>
    <achat_bcd_etat2 ref="achat_bcd_etat2"  ></achat_bcd_etat2>
    <achat_bcd_etat3 ref="achat_bcd_etat3"  ></achat_bcd_etat3>
    <achat_bcd_etat4 ref="achat_bcd_etat4"  ></achat_bcd_etat4>
    <achat_bcd_etat5 ref="achat_bcd_etat5"  ></achat_bcd_etat5>

    <achat_bondereception_etat0 ref="achat_bondereception_etat0"  ></achat_bondereception_etat0>
    <achat_demandedachat_etat0 ref="achat_demandedachat_etat0"  ></achat_demandedachat_etat0>
    <achat_facture_etat0 ref="achat_facture_etat0"  ></achat_facture_etat0>
    <achat_retour_etat0 ref="achat_retour_etat0"  ></achat_retour_etat0>

    <vente_bcd_etat0 ref="vente_bcd_etat0"  ></vente_bcd_etat0>
    <vente_bl_etat0 ref="vente_bl_etat0"  ></vente_bl_etat0>
    <vente_bpl_etat0 ref="vente_bpl_etat0"  ></vente_bpl_etat0>
    <vente_facture_etat0 ref="vente_facture_etat0"  ></vente_facture_etat0>
    <vente_retour_etat0 ref="vente_retour_etat0"  ></vente_retour_etat0>


    <achat_bondereception_etat1 ref="achat_bondereception_etat1"  ></achat_bondereception_etat1>
    <achat_bondereception_etat2 ref="achat_bondereception_etat2"  ></achat_bondereception_etat2>
    <achat_bondereception_etat3 ref="achat_bondereception_etat3"  ></achat_bondereception_etat3>
    <achat_bondereception_etat4 ref="achat_bondereception_etat4"  ></achat_bondereception_etat4>
    <achat_bondereception_etat5 ref="achat_bondereception_etat5"  ></achat_bondereception_etat5>
    <achat_demandedachat_etat1 ref="achat_demandedachat_etat1"  ></achat_demandedachat_etat1>
    <achat_demandedachat_etat2 ref="achat_demandedachat_etat2"  ></achat_demandedachat_etat2>
    <achat_demandedachat_etat3 ref="achat_demandedachat_etat3"  ></achat_demandedachat_etat3>
    <achat_demandedachat_etat4 ref="achat_demandedachat_etat4"  ></achat_demandedachat_etat4>
    <achat_demandedachat_etat5 ref="achat_demandedachat_etat5"  ></achat_demandedachat_etat5>
    <achat_facture_etat1 ref="achat_facture_etat1"  ></achat_facture_etat1>
    <achat_facture_etat2 ref="achat_facture_etat2"  ></achat_facture_etat2>
    <achat_facture_etat3 ref="achat_facture_etat3"  ></achat_facture_etat3>
    <achat_facture_etat4 ref="achat_facture_etat4"  ></achat_facture_etat4>
    <achat_facture_etat5 ref="achat_facture_etat5"  ></achat_facture_etat5>
    <achat_retour_etat1 ref="achat_retour_etat1"  ></achat_retour_etat1>
    <achat_retour_etat2 ref="achat_retour_etat2"  ></achat_retour_etat2>
    <achat_retour_etat3 ref="achat_retour_etat3"  ></achat_retour_etat3>
    <achat_retour_etat4 ref="achat_retour_etat4"  ></achat_retour_etat4>
    <achat_retour_etat5 ref="achat_retour_etat5"  ></achat_retour_etat5>
    <vente_bcd_etat1 ref="vente_bcd_etat1"  ></vente_bcd_etat1>
    <vente_bcd_etat2 ref="vente_bcd_etat2"  ></vente_bcd_etat2>
    <vente_bcd_etat3 ref="vente_bcd_etat3"  ></vente_bcd_etat3>
    <vente_bcd_etat4 ref="vente_bcd_etat4"  ></vente_bcd_etat4>
    <vente_bcd_etat5 ref="vente_bcd_etat5"  ></vente_bcd_etat5>
    <vente_bl_etat1 ref="vente_bl_etat1"  ></vente_bl_etat1>
    <vente_bl_etat2 ref="vente_bl_etat2"  ></vente_bl_etat2>
    <vente_bl_etat3 ref="vente_bl_etat3"  ></vente_bl_etat3>
    <vente_bl_etat4 ref="vente_bl_etat4"  ></vente_bl_etat4>
    <vente_bl_etat5 ref="vente_bl_etat5"  ></vente_bl_etat5>
    <vente_bpl_etat1 ref="vente_bpl_etat1"  ></vente_bpl_etat1>

    <vente_bpl_etat2 ref="vente_bpl_etat2"  ></vente_bpl_etat2>
    <vente_bpl_etat3 ref="vente_bpl_etat3"  ></vente_bpl_etat3>
    <vente_bpl_etat4 ref="vente_bpl_etat4"  ></vente_bpl_etat4>
    <vente_bpl_etat5 ref="vente_bpl_etat5"  ></vente_bpl_etat5>
    <vente_devis_etat0 ref="vente_devis_etat0"  ></vente_devis_etat0>
    <vente_devis_etat1 ref="vente_devis_etat1"  ></vente_devis_etat1>
    <vente_devis_etat2 ref="vente_devis_etat2"  ></vente_devis_etat2>
    <vente_devis_etat3 ref="vente_devis_etat3"  ></vente_devis_etat3>
    <vente_devis_etat4 ref="vente_devis_etat4"  ></vente_devis_etat4>
    <vente_devis_etat5 ref="vente_devis_etat5"  ></vente_devis_etat5>
    <vente_facture_etat0 ref="vente_facture_etat0"  ></vente_facture_etat0>
    <vente_facture_etat1 ref="vente_facture_etat1"  ></vente_facture_etat1>
    <vente_facture_etat2 ref="vente_facture_etat2"  ></vente_facture_etat2>
    <vente_facture_etat3 ref="vente_facture_etat3"  ></vente_facture_etat3>
    <vente_facture_etat4 ref="vente_facture_etat4"  ></vente_facture_etat4>
    <vente_facture_etat5 ref="vente_facture_etat5"  ></vente_facture_etat5>
    <vente_retour_etat1 ref="vente_retour_etat1"  ></vente_retour_etat1>
    <vente_retour_etat2 ref="vente_retour_etat2"  ></vente_retour_etat2>
    <vente_retour_etat3 ref="vente_retour_etat3"  ></vente_retour_etat3>
    <vente_retour_etat4 ref="vente_retour_etat4"  ></vente_retour_etat4>
    <vente_retour_etat5 ref="vente_retour_etat5"  ></vente_retour_etat5>







</template>

<script>
    /* import templatesite from "@/components/template/apptemplate";
     import appheader from "@/components/template/appheader";
     import appsidebar from "@/components/template/appsidebar";*/


    import modalcustomlib from "@/components/views/pages/gestioncommerciale/modal_customlib";

    const axios =require('axios');

    import modalopenform from "@/components/views/pages/gestioncommerciale/achatvente/modal_openform";
    import normalisationdelafacture from "@/components/views/pages/gestioncommerciale/achatvente/normalisationdelafacture";
    import modalform from "@/components/views/pages/gestioncommerciale/achatvente/default/form";
    import modalformsuppression from "@/components/views/pages/gestioncommerciale/achatvente/default/formsuppression";
    import modalimpressiondoc from "@/components/views/pages/stock/mvtstock/modal_etatdocstock";
    import modaltransformation from "@/components/views/pages/gestioncommerciale/modal_transformation";







    import achat_bcd_etat0 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_bcd/0";
    import achat_bcd_etat1 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_bcd/1";
    import achat_bcd_etat2 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_bcd/2";
    import achat_bcd_etat3 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_bcd/3";
    import achat_bcd_etat4 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_bcd/4";
    import achat_bcd_etat5 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_bcd/5";

    import achat_bondereception_etat0 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_bondereception/0";
    import achat_bondereception_etat1 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_bondereception/1";
    import achat_bondereception_etat2 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_bondereception/2";
    import achat_bondereception_etat3 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_bondereception/3";
    import achat_bondereception_etat4 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_bondereception/4";
    import achat_bondereception_etat5 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_bondereception/5";

    import achat_demandedachat_etat0 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_demandedachat/0";
    import achat_demandedachat_etat1 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_demandedachat/1";
    import achat_demandedachat_etat2 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_demandedachat/2";
    import achat_demandedachat_etat3 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_demandedachat/3";
    import achat_demandedachat_etat4 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_demandedachat/4";
    import achat_demandedachat_etat5 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_demandedachat/5";

    import achat_facture_etat0 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_facture/0";
    import achat_facture_etat1 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_facture/1";
    import achat_facture_etat2 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_facture/2";
    import achat_facture_etat3 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_facture/3";
    import achat_facture_etat4 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_facture/4";
    import achat_facture_etat5 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_facture/5";


    import achat_retour_etat0 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_retour/0";
    import achat_retour_etat1 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_retour/1";
    import achat_retour_etat2 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_retour/2";
    import achat_retour_etat3 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_retour/3";
    import achat_retour_etat4 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_retour/4";
    import achat_retour_etat5 from "@/components/views/pages/gestioncommerciale/achatvente/etat/achat_retour/5";

    import vente_bcd_etat0 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bcd/0";
    import vente_bcd_etat1 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bcd/1";
    import vente_bcd_etat2 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bcd/2";
    import vente_bcd_etat3 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bcd/3";
    import vente_bcd_etat4 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bcd/4";
    import vente_bcd_etat5 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bcd/5";

    import vente_bl_etat0 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bl/0";
    import vente_bl_etat1 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bl/1";
    import vente_bl_etat2 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bl/2";
    import vente_bl_etat3 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bl/3";
    import vente_bl_etat4 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bl/4";
    import vente_bl_etat5 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bl/5";

    import vente_bpl_etat0 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bpl/0";
    import vente_bpl_etat1 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bpl/1";
    import vente_bpl_etat2 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bpl/2";
    import vente_bpl_etat3 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bpl/3";
    import vente_bpl_etat4 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bpl/4";
    import vente_bpl_etat5 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_bpl/5";

    import vente_devis_etat0 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_devis/0";
    import vente_devis_etat1 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_devis/1";
    import vente_devis_etat2 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_devis/2";
    import vente_devis_etat3 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_devis/3";
    import vente_devis_etat4 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_devis/4";
    import vente_devis_etat5 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_devis/5";

    import vente_facture_etat0 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_facture/0";
    import vente_facture_etat1 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_facture/1";
    import vente_facture_etat2 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_facture/2";
    import vente_facture_etat3 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_facture/3";
    import vente_facture_etat4 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_facture/4";
    import vente_facture_etat5 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_facture/5";

    import vente_retour_etat0 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_retour/0";
    import vente_retour_etat1 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_retour/1";
    import vente_retour_etat2 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_retour/2";
    import vente_retour_etat3 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_retour/3";
    import vente_retour_etat4 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_retour/4";
    import vente_retour_etat5 from "@/components/views/pages/gestioncommerciale/achatvente/etat/vente_retour/5";
    import qrcodetoimage from 'qrcode';


    import {isset, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },
          /*  searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

                // console.log("  oldvalue  searchtrierletableau        **************** ",oldvalue)
                // console.log("  newvalue   searchtrierletableau       **************** ",newvalue)

            }*/

        },
        computed:{

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

                /*if (this.searchtrierletableau!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {

                        return   post.removeline===parseFloat(t.searchtrierletableau)

                    })

                }*/


                if (this.searchword!==''){
                    let t=this;



                    mvalue = mvalue.filter( function (post) {
                        return    post.numdoc.toLowerCase().includes(t.searchword.toLowerCase())
                                 || post.magname.toLowerCase().includes(t.searchword.toLowerCase())
                               /*  || post.numbon.toLowerCase().includes(t.searchword.toLowerCase())*/

                    })

                }



                return this.paginate(mvalue) ;
            },
        },
        components: {
            modalform,modalformsuppression,
            modaltransformation,modalcustomlib,


            normalisationdelafacture,
            modalimpressiondoc,
            modalopenform,
           achat_bcd_etat0,
            achat_bcd_etat1,
            achat_bcd_etat2,
            achat_bcd_etat3,
            achat_bcd_etat4,
            achat_bcd_etat5,

            achat_bondereception_etat0,
           achat_demandedachat_etat0,
            achat_facture_etat0,
            achat_retour_etat0,
            vente_bcd_etat0,
            vente_bpl_etat0,
            vente_bl_etat0,
            vente_retour_etat0,

           achat_bondereception_etat1,
            achat_bondereception_etat2,
            achat_bondereception_etat3,
            achat_bondereception_etat4,
            achat_bondereception_etat5,
            achat_demandedachat_etat1,
            achat_demandedachat_etat2,
            achat_demandedachat_etat3,
            achat_demandedachat_etat4,
            achat_demandedachat_etat5,
            achat_facture_etat1,
            achat_facture_etat2,
            achat_facture_etat3,
            achat_facture_etat4,
            achat_facture_etat5,
            achat_retour_etat1,
            achat_retour_etat2,
            achat_retour_etat3,
            achat_retour_etat4,
            achat_retour_etat5,
            vente_bcd_etat1,
            vente_bcd_etat2,
            vente_bcd_etat3,
            vente_bcd_etat4,
            vente_bcd_etat5,
            vente_bl_etat1,
            vente_bl_etat2,
            vente_bl_etat3,
            vente_bl_etat4,
            vente_bl_etat5,
            vente_bpl_etat1,
            vente_bpl_etat2,
            vente_bpl_etat3,
            vente_bpl_etat4,
            vente_bpl_etat5,
            vente_devis_etat0,
            vente_devis_etat1,
            vente_devis_etat2,
            vente_devis_etat3,
            vente_devis_etat4,
            vente_devis_etat5,
            vente_facture_etat0,
            vente_facture_etat1,
            vente_facture_etat2,
            vente_facture_etat3,
            vente_facture_etat4,
            vente_facture_etat5,
            vente_retour_etat1,
            vente_retour_etat2,
            vente_retour_etat3,
            vente_retour_etat4,
            vente_retour_etat5,


        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                datatable:[],
                otherdata:[],
                listedupersonnel:[],
                datainputlibelleforms:{},
                customlibelleforms:{},
                datatiers:[],
                typedoc:"",
                typededocument:"",
                messageerror:"",
                messagesucces:"",
                indexbutton:'1d',

                accesnewbtn:false,
                accesupdatebtn:false,
                accesdeletebtn:false,


                searchword:"",
                searchtrierletableau:"",

                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 4,
                Defaultpage: 1,
                DefaultperPage: 4,
                pages: [],
            };
        },
        methods: {

            btnajouter(){


                let mname

                let  Item= {
                    pid:'',
                    mag:null,
                    datedoc:this.$store.state.appserverdate,
                    docobservation:'',
                    docnum:'',
                    docnumother:'',
                    docpid:0,
                    doctyp:this.typedoc,
                    infodoc:{
                        infolibre:'',
                        infolibre1:'',
                        infolibre2:'',
                        infolibre3:'',
                    },
                    jsonotherdonne:{
                        datedelivraison:'',
                        dateechancefacture:'',
                        tpdoc:"",
                        tredocument:"",
                        magvers:null,
                        mtypprixdevente:'prixvente',
                        collaborateur:null,
                        cltfrs:null,
                        moderglt:null,
                        cdtreglement:null,
                        appliquetva:1,
                        aib:0,
                        adresseexpedition:{
                            infolibre:'',
                            infolibre1:'',
                            infolibre2:'',
                            infolibre3:'',
                        },
                        infodoc:{
                            infolibre:'',
                            infolibre1:'',
                            infolibre2:'',
                            infolibre3:'',
                            infolibre4:'',
                            infolibre5:'',
                            infolibre6:'',
                            infolibre7:'',
                            infolibre8:'',
                            infolibre9:'',
                            infolibre10:'',
                            infolibre11:'',
                        },
                        piedsinfodoc:{
                            infolibre:'',
                            infolibre1:'',
                            infolibre2:'',
                            infolibre3:'',
                        },


                    },
                    mtnt:{

                        docmtntaib: "0",
                        docmtntremise: "0",
                        docmtnttotalht: "0",
                        docmtnttotalttc: "0",
                        doctva: "0",
                        doccalmtnttotalht: "0",
                        libaib: "AIB"


                    },
                    params:{
                        param1:null,
                        param2:null,
                        param3:null,
                        param4:null,
                    },
                    jsondoc:{}
                }



                if (this.typedoc==='DAF'){
                    mname="Enregistrer une demandes d'achat"
                }

                if (this.typedoc==='BCF'){
                    mname="Enregistrer un bon de commande fournisseur"
                }

                if (this.typedoc==='BRF'){
                    mname="Enregistrer un bon de réception fournisseur"
                }

                if (this.typedoc==='FR'){
                    mname="Enregistrer une facture fournisseur"
                }

                if (this.typedoc==='FRG'){
                    mname="Enregistrer une facture consolidée(fournisseur)"
                }

                if (this.typedoc==='RF'){
                    mname="Enregistrer un bon de retour fournisseur"
                }

                if (this.typedoc==='PF'){
                    mname="Enregistrer un devis"
                }

                if (this.typedoc==='BC'){
                    mname="Enregistrer une bon de commande client"
                }

                if (this.typedoc==='BPL'){
                    mname="Enregistrer un bon de préparation de livraison"
                }

                if (this.typedoc==='BL'){
                    mname="Enregistrer un bon de livraisson clients"
                }

                if (this.typedoc==='F'){
                    mname="Enregistrer une facture clients"
                }

                if (this.typedoc==='FG'){
                    mname="Enregistrer une facture consolidée(clients)"
                }

                if (this.typedoc==='BR'){
                    mname="Enregistrer un bon de retour clients"
                }

                if (this.typedoc==='FR'){
                    mname="Enregistrer une facture d'avoir clients"
                }



                this.$refs.modalform.show(mname,"add",this.typedoc,Item,this.otherdata,this.datainputlibelleforms,this.typededocument,this.customlibelleforms);

            },

            btnnormalisationdelafacture(value){

                this.$refs.normalisationdelafacture.show(value,this.typedoc,this.typededocument);
            },

            btncustomlib(){

                this.$refs.modalcustomlib.show(this.customlibelleforms,'achat');
            },

            handleElementSelectedcustomlib(elementSelectionne) {

                this.customlibelleforms=elementSelectionne.value



            },


            loadcustomlib(){

                let $CUSTOMLIBELLE=JwtService.getCUSTOMLIBELLE()
                //appcustomlibelleachat

                if (isset($CUSTOMLIBELLE)){
                    this.customlibelleforms=JSON.parse($CUSTOMLIBELLE);

                }
                else{

                    this.customlibelleforms=this.$store.state.appcustomlibelleachat;

                    JwtService.setCUSTOMLIBELLE(JSON.stringify(this.customlibelleforms))


                }



            },


            async load(){

                this.isdisplay=true;

                        this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/jkebfgescomporziprodpfachatventeegl745874stock771714dfe/gescom/default/all/'+this.typededocument+'/'+this.typedoc+'/'+this.indexbutton+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){


                            t.datatable=[];
                            t.datatable=res.data.m;
                             t.isdisplay=true;

                        }
                        else{
                            t.datatable=[];

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })




            },


            async loadtransformation($keysearch){



                        this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/jkebfgescomporziprodpfachatventeegl745874stock771714dfe/gescom/default/all/'+this.typededocument+'/'+this.typedoc+'/'+this.indexbutton+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){

                        if(res.data.success){


                            t.datatable=[];
                            t.datatable=res.data.m;



                            let $chekvalue={};



                            for (let $line of t.datatable){

                                if ($line.pid===$keysearch){
                                    $chekvalue=$line
                                    break;
                                }

                            }

                            if (Object.keys($chekvalue).length>0){

                                t.btnmodifier($chekvalue)

                            }







                        }
                        else{
                            t.datatable=[];

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })




            },



            async loadreturnotherdata(){

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/jkebfiuebffeznfoieznofibeubqsyudfyegl745874stock771714dfe/gescom/othetdskd8755dsdhisd/'+this.typededocument+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){


                            t.otherdata=[];

                            t.otherdata=res.data.otherdata;

                            // JwtService.setVALUEDATAPRICE(JSON.stringify(t.datainputlibelleforms))

                        }
                        else{
                            t.otherdata=[];

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })
                //  }



            },

            async btnimpression(value){

                 //this.$refs.modalimpressiondoc.show(value);

                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/jkebfiuebffeznfoieznofibeubqsyudfyegl745874stock771714dfe/stock/'+value.numdoc+'/'+value.pid+'/open/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){


                           // t.listedatatableproduit=[];
                           // t.listedatatableproduit=res.data.m;

                          /*  t.Items.jsonotherdonne=Items.jsonotherdonne;
                            t.Items.mag=Items.mag;
                            t.Items.pid=Items.pid;
                            t.ItemsArticle.docpid=Items.pid;
                            t.Items.params=Items.params;
                            t.Items.mtnt=Items.mtnt;
                            t.Items.docnum=Items.numdoc;*/


                            /*t.Items.docnumother=Items.docnumother;
                            t.Items.docpid=Items.pid;
                            t.Items.pid=Items.pid;
                            t.Items.infodoc=Items.infodoc;
                            t.Items.datedoc=Items.datedoc;
                            t.Items.doctyp=Items.doctyp;
                            t.Items.docobservation=Items.observations;*/


                            //t.listearticles=response.data.allprod;

                            value.alllignedoc=res.data.alllignedoc;

                            /*t.listedatatable=res.data.alllignedoc;
                            t.mydocmag=Items.mag.pid;
                            t.ItemsArticle.docmag=Items.mag.pid;
                            //t.itemArticle.docnum=value.numdoc;
                            t.ItemsArticle.docnum=Items.pid;
                            t.ItemsArticle.doctp=Items.doctyp;
                            t.ItemsArticle.docnumother=Items.docotherref;*/


                            t.callimprimer(value);

                            /*t.isdisplayviewarticle=true;
                            t.isdisplay=true;*/

                        }
                        else{
                            t.listedatatableproduit=[];
                            // t.isdisplay=true;

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })



            },

            callimprimer(value) {



                let   tabvalue={}



                tabvalue={
                    jsoncltfrs:{
                        typcltfrs:'',
                        raisonsociale:value.jsonotherdonne.cltfrs.name,
                        ifu:value.jsonotherdonne.cltfrs.ifu,
                        telephone:value.jsonotherdonne.cltfrs.telephone,
                        adresse:value.jsonotherdonne.cltfrs.adresse,
                        codeclient:value.jsonotherdonne.cltfrs.codecltfrs
                    },
                    jsoncol:{
                        name:'',
                    },
                    titresignataire:"",
                    nomdusignataire:"",
                    texteavance:String(value.jsonotherdonne.piedsinfodoc.infolibre),
                    textesole:String(value.jsonotherdonne.piedsinfodoc.infolibre1),
                    infolibre2:String(value.jsonotherdonne.piedsinfodoc.infolibre2),

                    infolibre:String(value.jsonotherdonne.infodoc.infolibre),
                    infolibre1:String(value.jsonotherdonne.infodoc.infolibre1),
                    infolibre22:String(value.jsonotherdonne.infodoc.infolibre2),
                    infolibre3:String(value.jsonotherdonne.infodoc.infolibre3),
                    infolibre4:String(value.jsonotherdonne.infodoc.infolibre4),
                    infolibre5:String(value.jsonotherdonne.infodoc.infolibre5),
                    infolibre6:String(value.jsonotherdonne.infodoc.infolibre6),
                    infolibre7:String(value.jsonotherdonne.infodoc.infolibre7),
                    infolibre8:String(value.jsonotherdonne.infodoc.infolibre8),
                    infolibre9:String(value.jsonotherdonne.infodoc.infolibre9),
                    infolibre10:String(value.jsonotherdonne.infodoc.infolibre10),
                    infolibre11:String(value.jsonotherdonne.infodoc.infolibre11),

                    libelledefacture:value.doctyp,
                    cltfrs:value.jsonotherdonne.cltfrs.name,
                    numdoc:value.numdoc,
                    operateur:value.saisiepar,
                    othernumdoc:value.numbon,

                    observations:value.mobservations,
                    date:value.datedoc,
                    items:value.alllignedoc,
                    montantht:parseFloat(value.mtnt.docmtnttotalht),
                    montanttva:parseFloat(value.mtnt.doctva),
                    montantremise:parseFloat(value.mtnt.docmtntremise),
                    montantnetapayer:parseFloat(value.mtnt.docmtnttotalttc),
                    montantaib:parseFloat(value.mtnt.docmtntaib),
                    libaib:value.mtnt.libaib,

                    formattext:'',

                    mecefsignature:'',
                    mecefnim:'',
                    mecefcompteur:'',
                    mecefheure:'',
                    mecefqrr:'',
                    normalise:0,


                    /************************** signature ****************/

                    signature:{
                        titredoc:"",
                        titresignataire:"",
                        namesignataire:"",
                        imgsignature:"",
                        datasignataires:[],
                        imgsignataires:[]
                    }




                }

                if(value.doctyp==='PF'){

                    tabvalue.signature.titredoc=this.$store.state.paramsapp.signataire.ventedevis.titredoc
                    tabvalue.signature.datasignataires=this.$store.state.paramsapp.signataire.ventedevis.signataires


                    tabvalue.jsoncltfrs.typcltfrs="CLIENT";

                    if (parseInt(this.$store.state.paramsapp.signataire.ventedevis.document)===0){
                        this.$refs.vente_devis_etat0.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventedevis.document)===1){
                        this.$refs.vente_devis_etat1.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventedevis.document)===2){
                        this.$refs.vente_devis_etat2.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventedevis.document)===3){
                        this.$refs.vente_devis_etat3.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventedevis.document)===4){
                        this.$refs.vente_devis_etat4.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventedevis.document)===5){
                        this.$refs.vente_devis_etat5.show(tabvalue);
                    }



                }


                if(value.doctyp==='BC'){

                    tabvalue.signature.titredoc=this.$store.state.paramsapp.signataire.ventebcd.titredoc
                    tabvalue.signature.datasignataires=this.$store.state.paramsapp.signataire.ventebcd.signataires


                    tabvalue.jsoncltfrs.typcltfrs="CLIENT";

                    if (parseInt(this.$store.state.paramsapp.signataire.ventebcd.document)===0){
                        this.$refs.vente_bcd_etat0.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.ventebcd.document)===1){
                        this.$refs.vente_bcd_etat1.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.ventebcd.document)===2){
                        this.$refs.vente_bcd_etat2.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.ventebcd.document)===3){
                        this.$refs.vente_bcd_etat3.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.ventebcd.document)===4){
                        this.$refs.vente_bcd_etat4.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.ventebcd.document)===5){
                        this.$refs.vente_bcd_etat5.show(tabvalue);
                    }



                }


                if(value.doctyp==='BPL'){

                    tabvalue.jsoncltfrs.typcltfrs="CLIENT";

                    if (parseInt(this.$store.state.paramsapp.signataire.ventebpl.document)===0){
                        this.$refs.vente_bpl_etat0.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.ventebpl.document)===1){

                        tabvalue.jsoncol.name=isset(value.jsonotherdonne.collaborateur)?value.jsonotherdonne.collaborateur.name:"";
                        let formattext='';

                        value.alllignedoc.forEach(function (line) {


                            if (formattext===''){


                                formattext=line.qtite+' '+line.article.libelle;

                            }else{

                                formattext+=', '+line.qtite+' '+line.article.libelle;

                            }

                        })

                        tabvalue.formattext=formattext;



                        tabvalue.signature.titredoc=this.$store.state.paramsapp.signataire.ventebpl.titredoc
                        tabvalue.signature.datasignataires=this.$store.state.paramsapp.signataire.ventebpl.signataires




                        this.$refs.vente_bpl_etat1.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.ventebpl.document)===2){
                        this.$refs.vente_bpl_etat2.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.ventebpl.document)===3){
                        this.$refs.vente_bpl_etat3.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.ventebpl.document)===4){
                        this.$refs.vente_bpl_etat4.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.ventebpl.document)===5){
                        this.$refs.vente_bpl_etat5.show(tabvalue);
                    }



                }

                if(value.doctyp==='BL'){
                    tabvalue.jsoncltfrs.typcltfrs="CLIENT";

                    tabvalue.signature.titredoc=this.$store.state.paramsapp.signataire.ventebl.titredoc
                    tabvalue.signature.datasignataires=this.$store.state.paramsapp.signataire.ventebl.signataires



                    if (parseInt(this.$store.state.paramsapp.signataire.ventebl.document)===0){
                        this.$refs.vente_bl_etat0.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventebl.document)===1){


                        tabvalue.jsoncol.name=value.jsonotherdonne.collaborateur.name;
                        let formattext='';

                        value.alllignedoc.forEach(function (line) {


                            if (formattext===''){


                                formattext=line.qtite+' '+line.article.libelle;

                            }else{

                                formattext+=', '+line.qtite+' '+line.article.libelle;

                            }

                        })

                        tabvalue.formattext=formattext;






                        this.$refs.vente_bl_etat1.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventebl.document)===2){
                        this.$refs.vente_bl_etat2.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventebl.document)===3){
                        this.$refs.vente_bl_etat3.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventebl.document)===4){
                        this.$refs.vente_bl_etat4.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventebl.document)===5){
                        this.$refs.vente_bl_etat5.show(tabvalue);
                    }



                }


                if(value.doctyp==='F'){

                    tabvalue.signature.titredoc=this.$store.state.paramsapp.signataire.ventefacture.titredoc
                    tabvalue.signature.datasignataires=this.$store.state.paramsapp.signataire.ventefacture.signataires

                    let urlimagegenerer="";

                    // Générer un QR code sous forme d'URL de données
                    qrcodetoimage.toDataURL(value.infodoc.doccollecte.data.qrCode, { width: 125, margin: 2 }, (err, url) => {
                        if (err) {
                            console.error(err);
                        } else {

                            urlimagegenerer=  url;
                        }
                    });




                    tabvalue.jsoncltfrs.typcltfrs="CLIENT";
                    if (value.infodoc.normalise===1){
                        tabvalue.mecefsignature=value.infodoc.doccollecte.data.codeMECeFDGI
                        tabvalue.mecefnim=value.infodoc.doccollecte.data.nim
                        tabvalue.mecefcompteur=value.infodoc.doccollecte.data.counters
                        tabvalue.mecefheure=value.infodoc.doccollecte.data.dateTime
                        //tabvalue.mecefqrr=value.infodoc.doccollecte.data.qrCode
                        tabvalue.mecefqrr=urlimagegenerer ,
                            tabvalue.normalise=value.infodoc.normalise
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventefacture.document)===0){

                        this.$refs.vente_facture_etat0.show(tabvalue);

                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventefacture.document)===1){


                        this.$refs.vente_facture_etat1.show(tabvalue);

                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventefacture.document)===2){

                        this.$refs.vente_facture_etat2.show(tabvalue);

                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventefacture.document)===3){

                        this.$refs.vente_facture_etat3.show(tabvalue);

                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventefacture.document)===4){

                        this.$refs.vente_facture_etat4.show(tabvalue);

                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.ventefacture.document)===5){

                        this.$refs.vente_facture_etat5.show(tabvalue);

                    }



                }


                if(value.doctyp==='BR'){
                    tabvalue.jsoncltfrs.typcltfrs="CLIENT";

                    tabvalue.signature.titredoc=this.$store.state.paramsapp.signataire.venteretour.titredoc
                    tabvalue.signature.datasignataires=this.$store.state.paramsapp.signataire.venteretour.signataires


                    let urlimagegenerer="";

                    // Générer un QR code sous forme d'URL de données
                    qrcodetoimage.toDataURL(value.infodoc.doccollecte.data.qrCode, { width: 125, margin: 2 }, (err, url) => {
                        if (err) {
                            console.error(err);
                        } else {

                            urlimagegenerer=  url;
                        }
                    });


                    if (value.infodoc.normalise===1){
                        tabvalue.mecefreference=value.infodoc.collecte.doccollecte.dataemecef.reference
                        tabvalue.mecefsignature=value.infodoc.collecte.doccollecte.data.codeMECeFDGI
                        tabvalue.mecefnim=value.infodoc.collecte.doccollecte.data.nim
                        tabvalue.mecefcompteur=value.infodoc.collecte.doccollecte.data.counters
                        tabvalue.mecefheure=value.infodoc.collecte.doccollecte.data.dateTime
                        //tabvalue.mecefqrr=value.infodoc.collecte.doccollecte.data.qrCode
                        tabvalue.mecefqrr=urlimagegenerer,
                            tabvalue.normalise=value.infodoc.normalise
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.venteretour.document)===0){
                        this.$refs.vente_retour_etat0.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.venteretour.document)===1){
                        this.$refs.vente_retour_etat1.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.venteretour.document)===2){
                        this.$refs.vente_retour_etat2.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.venteretour.document)===3){
                        this.$refs.vente_retour_etat3.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.venteretour.document)===4){
                        this.$refs.vente_retour_etat4.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.venteretour.document)===5){
                        this.$refs.vente_retour_etat5.show(tabvalue);
                    }



                }

                //**************************   ACHAT   **************************************//

                if(value.doctyp==='DAF'){

                    tabvalue.jsoncltfrs.typcltfrs="FOURNISSEUR";

                    tabvalue.signature.titredoc=this.$store.state.paramsapp.signataire.demandedachat.titredoc
                    tabvalue.signature.datasignataires=this.$store.state.paramsapp.signataire.demandedachat.signataires


                    if (parseInt(this.$store.state.paramsapp.signataire.demandedachat.document)===0){
                        this.$refs.achat_demandedachat_etat0.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.demandedachat.document)===1){
                        this.$refs.achat_demandedachat_etat1.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.demandedachat.document)===2){
                        this.$refs.achat_demandedachat_etat2.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.demandedachat.document)===3){
                        this.$refs.achat_demandedachat_etat3.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.demandedachat.document)===4){
                        this.$refs.achat_demandedachat_etat4.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.demandedachat.document)===5){
                        this.$refs.achat_demandedachat_etat5.show(tabvalue);
                    }



                }


                if(value.doctyp==='BCF'){

                    tabvalue.signature.titredoc=this.$store.state.paramsapp.signataire.achatbcd.titredoc
                    tabvalue.signature.datasignataires=this.$store.state.paramsapp.signataire.achatbcd.signataires


                    tabvalue.jsoncltfrs.typcltfrs="FOURNISSEUR / SUPPLIER";

                    if (parseInt(this.$store.state.paramsapp.signataire.achatbcd.document)===0){
                        this.$refs.achat_bcd_etat0.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.achatbcd.document)===1){

                        this.$refs.achat_bcd_etat1.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.achatbcd.document)===2){
                        this.$refs.achat_bcd_etat2.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.achatbcd.document)===3){
                        this.$refs.achat_bcd_etat3.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.achatbcd.document)===4){
                        this.$refs.achat_bcd_etat4.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.achatbcd.document)===5){
                        this.$refs.achat_bcd_etat5.show(tabvalue);
                    }



                }


                if(value.doctyp==='BRF'){

                    tabvalue.signature.titredoc=this.$store.state.paramsapp.signataire.achatbondereception.titredoc
                    tabvalue.signature.datasignataires=this.$store.state.paramsapp.signataire.achatbondereception.signataires


                    tabvalue.jsoncltfrs.typcltfrs="FOURNISSEUR";

                    if (parseInt(this.$store.state.paramsapp.signataire.achatbondereception.document)===0){
                        this.$refs.achat_bondereception_etat0.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.achatbondereception.document)===1){
                        this.$refs.achat_bondereception_etat1.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.achatbondereception.document)===2){
                        this.$refs.achat_bondereception_etat2.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.achatbondereception.document)===3){
                        this.$refs.achat_bondereception_etat3.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.achatbondereception.document)===4){
                        this.$refs.achat_bondereception_etat4.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.achatbondereception.document)===5){
                        this.$refs.achat_bondereception_etat5.show(tabvalue);
                    }



                }


                if(value.doctyp==='FR'){

                    tabvalue.jsoncltfrs.typcltfrs="FOURNISSEUR";

                    tabvalue.signature.titredoc=this.$store.state.paramsapp.signataire.achatfacture.titredoc
                    tabvalue.signature.datasignataires=this.$store.state.paramsapp.signataire.achatfacture.signataires


                    if (parseInt(this.$store.state.paramsapp.signataire.achatfacture.document)===0){
                        this.$refs.achat_facture_etat0.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.achatfacture.document)===1){
                        this.$refs.achat_facture_etat1.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.achatfacture.document)===2){
                        this.$refs.achat_facture_etat2.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.achatfacture.document)===3){
                        this.$refs.achat_facture_etat3.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.achatfacture.document)===4){
                        this.$refs.achat_facture_etat4.show(tabvalue);
                    }


                    if (parseInt(this.$store.state.paramsapp.signataire.achatfacture.document)===5){
                        this.$refs.achat_facture_etat5.show(tabvalue);
                    }



                }


                if(value.doctyp==='RF'){

                    tabvalue.jsoncltfrs.typcltfrs="FOURNISSEUR";

                    tabvalue.signature.titredoc=this.$store.state.paramsapp.signataire.achatretour.titredoc
                    tabvalue.signature.datasignataires=this.$store.state.paramsapp.signataire.achatretour.signataires


                    if (parseInt(this.$store.state.paramsapp.signataire.achatretour.document)===0){
                        this.$refs.achat_retour_etat0.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.achatretour.document)===1){
                        this.$refs.achat_retour_etat1.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.achatretour.document)===2){
                        this.$refs.achat_retour_etat2.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.achatretour.document)===3){
                        this.$refs.achat_retour_etat3.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.achatretour.document)===4){
                        this.$refs.achat_retour_etat4.show(tabvalue);
                    }

                    if (parseInt(this.$store.state.paramsapp.signataire.achatretour.document)===5){
                        this.$refs.achat_retour_etat5.show(tabvalue);
                    }



                }








            },



            btntransformationdoc(value){


                 this.$refs.modaltransformation.show("",value,this.typedoc,this.typededocument);

            },

            handleElementSelectedtransformation(elementSelectionne) {

                /* if (elementSelectionne.typ==="add"){
                     this.jsonarticles.push(elementSelectionne.value)
                     this.newsjsonarticles.push(elementSelectionne.value)
                 }*/


                if (elementSelectionne.typ==='1'){

                    this.typedoc=elementSelectionne.mynewtypdoc

                    if (this.typedoc==='DAF'){
                        this.titlemodal="Liste des demandes d'achat"
                    }

                    if (this.typedoc==='BCF'){
                        this.titlemodal="Liste des bons de commande fournisseur"
                    }

                    if (this.typedoc==='BRF'){
                        this.titlemodal="Liste des bons de réception fournisseur"
                    }

                    if (this.typedoc==='FR'){
                        this.titlemodal="Liste des factures fournisseur"
                    }

                    if (this.typedoc==='FRG'){
                        this.titlemodal="Liste des factures consolidées (fournisseur)"
                    }

                    if (this.typedoc==='RF'){
                        this.titlemodal="Liste des bons de retour fournisseur"
                    }

                    if (this.typedoc==='PF'){
                        this.titlemodal="Liste des devis"
                    }

                    if (this.typedoc==='BC'){
                        this.titlemodal="Liste des bon de commande client"
                    }

                    if (this.typedoc==='BPL'){
                        this.titlemodal="Liste des bons de préparation de livraison"
                    }

                    if (this.typedoc==='BL'){
                        this.titlemodal="Liste des bons de livraisson clients"
                    }

                    if (this.typedoc==='F'){
                        this.titlemodal="Liste des factures clients"
                    }

                    if (this.typedoc==='FG'){
                        this.titlemodal="Liste des factures consolidées (clients)"
                    }

                    if (this.typedoc==='BR'){
                        this.titlemodal="Liste des bons de retour clients"
                    }

                    if (this.typedoc==='FA'){
                        this.titlemodal="Liste des factures d'avoir clients"
                    }

                    this.accesbutton();

                    this.indexbutton='1d';
                    this.loadtransformation(elementSelectionne.keypid)







                }



            },

            btnmodifier(value){

                let titlemodal="Modifier le document "+value.numdoc

                let Item=value

                this.$refs.modalform.showupdate(titlemodal,"update",this.typedoc,Item,this.otherdata,this.datainputlibelleforms,this.typededocument,this.customlibelleforms);


            },
            btnopen(value){


                let titlemodal="Document "+value.numdoc

                let Item=value

                this.$refs.modalopenform.showupdate(titlemodal,"update",this.typedoc,Item,this.otherdata);

            },

            btnsuppression(value){

                if (value.useline===1 || value.useline==='1'){

                    toastr['error']( "Impossible de supprimer cette ligne. Veuillez contacter votre administrateur. ","<b></b>");
                    return
                }


                let titlemodal="VOULLEZ-VOUS VRAIMENT SUPPRIMER LE DOCUMENT "+value.numdoc+" ?";

                this.$refs.modalformsuppression.show(titlemodal,value,this.typedoc,this.typededocument);

            },

            close(){

                this.isdisplay=false;

                //this.$emit('closed');

            },

            selected(datavalue){


                // Suppose que vous avez un objet représentant l'élément sélectionné
                const elementSelectionne = { typ: this.typedoc, value: datavalue};

                // Émettre un événement personnalisé avec l'élément sélectionné
                this.$emit('elementSelectionne', elementSelectionne);

                // Fermer le modal ou effectuer d'autres actions nécessaires
                this.isdisplay=false;
            },


            async loadinputlibelleforms(){



                let $VALUEDATAPRICE=JwtService.getVALUEDATAPRICE()

                if (isset($VALUEDATAPRICE)){

                    this.datainputlibelleforms=JSON.parse($VALUEDATAPRICE);


                }
                else{
                    const t = this;
                    let url=process.env.VUE_APP_API_URL+'/12478855libelinputindex2iofdofiloadexp2/'+this.$store.state.appsociete+'/'+randomString();
                    let response =   axios  .get(url,{
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete
                        } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                    })

                    let res= await response
                        .then(function (res){
                            t.$store.dispatch('lancerchargement',false)


                            if(res.data.success){


                                t.datainputlibelleforms=[];

                                t.datainputlibelleforms=res.data.valuedataprice;

                                JwtService.setVALUEDATAPRICE(JSON.stringify(t.datainputlibelleforms))


                            }
                            else{
                                t.datainputlibelleforms=[];

                            }


                        })
                        .catch((error)=>{

                                t.$store.dispatch('lancerchargement',false)
                            }
                        )
                        .finally(() =>{

                            t.$store.dispatch('lancerchargement',false)


                        })
                }



            },


            show(titlemodal,typedoc,typededocument){


                this.messagesucces="";
                this.messageerror="";
                this.titlemodal=titlemodal
                /* this.datatable=listdata
                 */

                this.indexbutton='1d';
                this.datatable=[];

                this.typedoc=typedoc
                this.typededocument=typededocument

                this.accesbutton();

                this.loadcustomlib()
                this.loadinputlibelleforms()
                this.loadreturnotherdata()
                this.load()



            },

            accesbutton(){


                this.accesnewbtn=false;
                this.accesupdatebtn=false;
                this.accesdeletebtn=false;


                if (this.typedoc==='DAF'){
                    this.accesnewbtn=this.$store.state.accescomponent.ccbtncommercialachatdemandeachat[0].permission;
                    this.accesupdatebtn=this.$store.state.accescomponent.ccbtncommercialachatdemandeachat[1].permission;
                    this.accesdeletebtn=this.$store.state.accescomponent.ccbtncommercialachatdemandeachat[2].permission;

                }

                if (this.typedoc==='BCF'){
                    this.accesnewbtn=this.$store.state.accescomponent.ccbtncommercialachatbondecommande[0].permission;
                    this.accesupdatebtn=this.$store.state.accescomponent.ccbtncommercialachatbondecommande[1].permission;
                    this.accesdeletebtn=this.$store.state.accescomponent.ccbtncommercialachatbondecommande[2].permission;

                }

                if (this.typedoc==='BRF'){
                    this.accesnewbtn=this.$store.state.accescomponent.ccbtncommercialachatbondereception[0].permission;
                    this.accesupdatebtn=this.$store.state.accescomponent.ccbtncommercialachatbondereception[1].permission;
                    this.accesdeletebtn=this.$store.state.accescomponent.ccbtncommercialachatbondereception[2].permission;

                }

                if (this.typedoc==='FR'){
                    this.accesnewbtn=this.$store.state.accescomponent.ccbtncommercialachatfacture[0].permission;
                    this.accesupdatebtn=this.$store.state.accescomponent.ccbtncommercialachatfacture[1].permission;
                    this.accesdeletebtn=this.$store.state.accescomponent.ccbtncommercialachatfacture[2].permission;

                }

                if (this.typedoc==='FRG'){

                    this.accesnewbtn=true

                }

                if (this.typedoc==='RF'){
                    this.accesnewbtn=this.$store.state.accescomponent.ccbtncommercialachatretoursurachat[0].permission;
                    this.accesupdatebtn=this.$store.state.accescomponent.ccbtncommercialachatretoursurachat[1].permission;
                    this.accesdeletebtn=this.$store.state.accescomponent.ccbtncommercialachatretoursurachat[2].permission;

                }

                if (this.typedoc==='PF'){
                    this.accesnewbtn=this.$store.state.accescomponent.ccbtncommercialventedevis[0].permission;
                    this.accesupdatebtn=this.$store.state.accescomponent.ccbtncommercialventedevis[1].permission;
                    this.accesdeletebtn=this.$store.state.accescomponent.ccbtncommercialventedevis[2].permission;

                }

                if (this.typedoc==='BC'){
                    this.accesnewbtn=this.$store.state.accescomponent.ccbtncommercialventebondecommande[0].permission;
                    this.accesupdatebtn=this.$store.state.accescomponent.ccbtncommercialventebondecommande[1].permission;
                    this.accesdeletebtn=this.$store.state.accescomponent.ccbtncommercialventebondecommande[2].permission;

                }

                if (this.typedoc==='BPL'){

                    this.accesnewbtn=this.$store.state.accescomponent.ccbtncommercialventebondepreparationlivraison[0].permission;
                    this.accesupdatebtn=this.$store.state.accescomponent.ccbtncommercialventebondepreparationlivraison[1].permission;
                    this.accesdeletebtn=this.$store.state.accescomponent.ccbtncommercialventebondepreparationlivraison[2].permission;

                }

                if (this.typedoc==='BL'){

                    this.accesnewbtn=this.$store.state.accescomponent.ccbtncommercialventebondelivraison[0].permission;
                    this.accesupdatebtn=this.$store.state.accescomponent.ccbtncommercialventebondelivraison[1].permission;
                    this.accesdeletebtn=this.$store.state.accescomponent.ccbtncommercialventebondelivraison[2].permission;

                }

                if (this.typedoc==='F'){

                    this.accesnewbtn=this.$store.state.accescomponent.ccbtncommercialventefacture[0].permission;
                    this.accesupdatebtn=this.$store.state.accescomponent.ccbtncommercialventefacture[1].permission;
                    this.accesdeletebtn=this.$store.state.accescomponent.ccbtncommercialventefacture[2].permission;


                }

                if (this.typedoc==='FG'){
                    this.accesnewbtn=true
                }

                if (this.typedoc==='BR'){

                    this.accesnewbtn=this.$store.state.accescomponent.ccbtncommercialventeretoursurvente[0].permission;
                    this.accesupdatebtn=this.$store.state.accescomponent.ccbtncommercialventeretoursurvente[1].permission;
                    this.accesdeletebtn=this.$store.state.accescomponent.ccbtncommercialventeretoursurvente[2].permission;


                }

                if (this.typedoc==='FR'){
                    this.accesnewbtn=true
                }





            },






            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>


    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 99%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>