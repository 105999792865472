// src/services/socketService.js
import { io } from 'socket.io-client';

class SocketService {
    constructor() {
        this.socket = null;
        this.onMessageCallback = null;
        this.onPrivateMessageCallback = null;
    }

    initializeSocket() {
        if (!this.socket) {
            //this.socket = io(url);
            this.socket  = io(process.env.VUE_APP_API_URL_SOCKET, {
                transports: ['websocket'], // Utilise uniquement WebSocket
                secure: process.env.VUE_APP_API_URL.startsWith('https'), // Active le mode sécurisé si l'URL commence par 'https'
                reconnectionAttempts: 5, // Nombre de tentatives de reconnexion
                reconnectionDelay: 1000, // Délai entre les tentatives en ms
                rejectUnauthorized: false // Rejet des certificats non autorisés (à éviter en production)
            });


            this.socket.on('connect', () => {
                //console.log('Socket connecté');
            });

            this.socket.on('disconnect', () => {
               // console.log('Socket déconnecté');
            });

            // Gestion des messages publics
            this.socket.on('message', (msg) => {
                //console.log('Message reçu:', msg);
                if (this.onMessageCallback) {
                    this.onMessageCallback(msg);
                }
            });

            // Gestion des messages privés
            this.socket.on('privateMessage', (msg) => {
                //console.log('Message privé reçu:', msg);
                if (this.onPrivateMessageCallback) {
                    this.onPrivateMessageCallback(msg);
                }
            });
        }
    }

    //onListener(event, callback) {
    onListener(event) {
       if (this.socket) {
          // this.socket.on(event, callback);

           return new Promise((resolve) => {
               this.socket.on(event, (data) => {
                   console.log(`Message reçu pour ${event}:`, data);
                   resolve(data);  // Résoudre la promesse avec le message reçu
               });
           });




        } else {
            console.error('Socket non initialisé onListener');
        }

    }

    emitEvent(event, data) {


        if (this.socket) {
            this.socket.emit(event, data);
        } else {
            console.error('Socket non initialisé emitEvent');
        }


    }

    sendMessage(event, message) {
        if (this.socket) {
            this.socket.emit(event, message);
        } else {
            console.error('Socket non initialisé');
        }
    }

    onMessage(callback) {
        this.onMessageCallback = callback;
    }

    onPrivateMessage(callback) {
        this.onPrivateMessageCallback = callback;
    }

    joinRoom(room) {
        if (this.socket) {
            this.socket.emit('join_room', room);
        } else {
            console.error('Socket non initialisé');
        }
    }

    leaveRoom(room) {
        if (this.socket) {
            this.socket.emit('leaveRoom', room);
        } else {
            console.error('Socket non initialisé');
        }
    }

    sendPrivateMessage(room, message) {
        if (this.socket) {
            //this.socket.emit('privateMessage', { room, message });
            this.socket.emit('messageaccueil', { room, message });
        } else {
            console.error('Socket non initialisé');
        }
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }
    }
}

export const socketService = new SocketService();
