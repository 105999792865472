/*import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)*/

import {randomString} from "@/allfunction";
import { socketService } from '@/service/socketService';
const JwtService=require("../common/jwt.service");
const axios =require('axios');
import { createStore } from 'vuex';

const state = {
    niveauclickmenu:0,
    myscreenSize:0,
    classdefautmenu:"",
    isAuthenticated:false,
    beartoken:'',
    lancerchargement:false,

    totalmoconsomme:0,
    indexbtnsuividata:'1M',
    graphssuiviedata:{},



    userdata:[],
    isRole:[],
    allccessapp:[],
    appsocket: null,
    appsocketroom: "",
    notifications: [],
    settings: {
        theme: 'light',
        language: 'fr',
    },
    rooms: [],
    privateMessages: {},



    appuserpicture:"",
    appusername:"",
    approle:"",
    appsociete:"",
    appserverdate:"",
    appcustomlibelleachat:{
        adresseexpedition:{
            informationlibre:'Information libre',
            informationlibre1:'Information libre 1',
            informationlibre2:'Information libre 2',
            informationlibre3:'Information libre 3',
            informationlibre4:'Information libre 4',
            informationlibre5:'Information libre 5',
            informationlibre6:'Information libre 6',
            informationlibre7:'Information libre 7',
            informationlibre8:'Information libre 8',
            informationlibre9:'Information libre 9',
            informationlibre10:'Information libre 10',
        },
        informationlibre:{
            informationlibre:'Information libre',
            informationlibre1:'Information libre 1',
            informationlibre2:'Information libre 2',
            informationlibre3:'Information libre 3',
            informationlibre4:'Information libre 4',
            informationlibre5:'Information libre 5',
            informationlibre6:'Information libre 6',
            informationlibre7:'Information libre 7',
            informationlibre8:'Information libre 8',
            informationlibre9:'Information libre 9',
            informationlibre10:'Information libre 10',
            informationlibre11:'Information libre 11',
            informationlibre12:'Information libre 12',
            informationlibre13:'Information libre 13',
            informationlibre14:'Information libre 14',
            informationlibre15:'Information libre 15',
        },
    },
    appcustomlibellefrsclt:{
        adresseexpedition:{
            informationlibre:'Information libre',
            informationlibre1:'Information libre 1',
            informationlibre2:'Information libre 2',
            informationlibre3:'Information libre 3 / Permis',
            informationlibre4:'Information libre 4',
            informationlibre5:'Information libre 5',
            informationlibre6:'Information libre 6',
            informationlibre7:'Information libre 7',
            informationlibre8:'Information libre 8',
            informationlibre9:'Information libre 9',
            informationlibre10:'Information libre 10',
        },
        informationlibre:{
            informationlibre:'Information libre',
            informationlibre1:'Information libre 1',
            informationlibre2:'Information libre 2',
            informationlibre3:'Information libre 3',
            informationlibre4:'Information libre 4',
            informationlibre5:'Information libre 5',
            informationlibre6:'Information libre 6',
            informationlibre7:'Information libre 7',
            informationlibre8:'Information libre 8',
            informationlibre9:'Information libre 9',
            informationlibre10:'Information libre 10',
            informationlibre11:'Information libre 11',
            informationlibre12:'Information libre 12',
            informationlibre13:'Information libre 13',
            informationlibre14:'Information libre 14',
            informationlibre15:'Information libre 15',
        },
    },




    baseurlenv:process.env.VUE_APP_ETAT,
    baseurlapi:process.env.VUE_APP_API_URL,
    baseurlapifile:process.env.VUE_APP_API_URL_FILE,




//***************************** configuration d'acces ****************************//

    datanotification:[],
    nbrenotification:0,
    paramsystem:[],
    paramsapp:[],



    accescomponent:{},




//***************************** PAIE VARIABLE A SUPPRIMER ****************************//




    devise:"",
    separationdevise:"",


    module:{
        "definition":1,
        "paie":1,
        "presence":1,
        "document":1,
        "etatedition":1,
        "paramsystem":1,
        "mpointeur":1,
        "absenceretard":1,
        "planningorregldepresense":2

    },





//***************************** FIN ****************************//


    sysmodule:{},




    etatlogo:"",
    etatraisonsocial:"",
    etatifu:"",
    etatrccm:"",
    etattelephone:"",
    etatadresse:"",






    //********************* gestions caisse  ******************//
    caisseencour:'',
    montantencaisse:0,
    ouverturedelacaisse:0,
    dateouverturecaisse:'',
    caisseinfo:{},
    listeallcaisses:[],
    listedesarticles:[],
    listedesarticlespourlacaisse:[],
    niveauaccescaisse:[],
    dateouverture:'',
    intitulecaisse:'',
    totaldepense:0,
    totalrecette:0,
    typc:0,


    //********************* gestions comptable  ******************//

    listedesexercicescomptables:[],
    anneexercicecomptable:'',
    exercicecomptable:'',
    infoexercicecomptable:{},
    niveauaccescomptable:[],





//***************************** configuration systéme ****************************//

    authorizesignatureelectronique:false,
    authorizechangementprix:false,
    authorizenormalisation:false,
    authorizeprix0:false,
    authorizefacturactionpartiel:false,
    authorizefacturepourgenereranalyse:false,






}


export default createStore({
    strict:true,
    state,
    getters:{

        niveauaccescaisse:function (state) {
            return state.niveauaccescaisse || []
        },

        niveauaccescomptable:function (state) {
            return state.niveauaccescomptable || []
        },

        etatlogo:function (state) {
            return state.etatlogo || ""
        },
        etatraisonsocial:function (state) {
            return state.etatraisonsocial || ""
        },
        etatifu:function (state) {
            return state.etatifu || ""
        },
        etatrccm:function (state) {
            return state.etatrccm || ""
        },
        etattelephone:function (state) {
            return state.etattelephone || ""
        },
        etatadresse:function (state) {
            return state.etatadresse || ""
        },


        paramsystem:function (state) {
            return state.paramsystem || []
        },

        datanotification:function (state) {
            return state.datanotification || []
        },

        nbrenotification:function (state) {
            return state.nbrenotification || 0
        },

        paramsapp:function (state) {
            return state.paramsapp || []
        },


        sysmodule:function (state) {
            return state.sysmodule || {}
        },

        nbrepersautorise:function (state) {
            return state.nbrepersautorise || 0
        },



        userdata:function (state) {
            return state.userdata || []
        },



        niveauclickmenu:function (state) {
            return state.niveauclickmenu || 0
        },

        myscreenSize:function (state) {
            return state.myscreenSize || 990
        },

        totalmoconsomme:function (state) {
            return state.totalmoconsomme || 0
        },

        indexbtnsuividata:function (state) {
            return state.indexbtnsuividata || '1M'
        },

        graphssuiviedata:function (state) {
            return state.graphssuiviedata || {}
        },



        isAuthenticated:function (state) {
            return state.isAuthenticated || false
        },

        beartoken:function (state) {
            return state.beartoken || ''
        },

        lancerchargement:function (state) {
            return state.lancerchargement || false
        },


        appusername:function (state) {
            return state.appusername || ""
        },

        approle:function (state) {
            return state.approle || ""
        },

        appuserpicture:function (state) {
            return state.appuserpicture || ""
        },

        appsocket:function (state) {
            return state.appsocket || null
        },

        appsocketroom:function (state) {
            return state.appsocketroom || ""
        },

        //*******************************************//




        accescomponent:function (state) {
            return state.accescomponent || []
        },


        authorizefacturepourgenereranalyse:function (state) {
            return state.authorizefacturepourgenereranalyse || "false"
        },
        authorizechangementprix:function (state) {
            return state.authorizechangementprix || "false"
        },
        authorizesignatureelectronique:function (state) {
            return state.authorizesignatureelectronique || "false"
        },
        authorizenormalisation:function (state) {
            return state.authorizenormalisation || "false"
        },
        authorizeprix0:function (state) {
            return state.authorizeprix0 || "false"
        },

        authorizefacturactionpartiel:function (state) {
            return state.authorizefacturactionpartiel || "false"
        },

        typc:function (state) {
            return state.typc || 0
        },

        totalrecette:function (state) {
            return state.totalrecette || 0
        },

        totaldepense:function (state) {
            return state.totaldepense || 0
        },

        intitulecaisse:function (state) {
            return state.intitulecaisse || ''
        },

        dateouverturecaisse:function (state) {
            return state.dateouverturecaisse || ''
        },

        anneexercicecomptable:function (state) {
            return state.anneexercicecomptable || ''
        },

        allccessapp:function (state) {
            return state.allccessapp || []
        },

        listeallcaisses:function (state) {
            return state.listeallcaisses || []
        },
        listedesexercicescomptables:function (state) {
            return state.listedesexercicescomptables || []
        },

        listedesarticles:function (state) {
            return state.listedesarticles || []
        },

        listedesarticlespourlacaisse:function (state) {
            return state.listedesarticlespourlacaisse || []
        },

        caisseinfo:function (state) {
            return state.caisseinfo || []
        },

        infoexercicecomptable:function (state) {
            return state.infoexercicecomptable || {}
        },

        ouverturedelacaisse:function (state) {
            return state.ouverturedelacaisse || 0
        },

        montantencaisse:function (state) {
            return state.montantencaisse || 0
        },

        caisseencour:function (state) {
            return state.caisseencour || ''
        },

        exercicecomptable:function (state) {
            return state.exercicecomptable || ''
        },



    },
    mutations:{

        niveauaccescaisse:function(state,{niveauaccescaisse}){

            state.niveauaccescaisse=niveauaccescaisse;
        },

        niveauaccescomptable:function(state,{niveauaccescomptable}){

            state.niveauaccescomptable=niveauaccescomptable;
        },



        paramsystem:function(state,{value}){

            state.paramsystem=value;
        },


        datanotification:function(state,{value}){

            state.datanotification=value;
        },


        nbrenotification:function(state,{value}){

            state.nbrenotification=value;
        },

        paramsapp:function(state,{value}){

            state.paramsapp=value;
        },


        sysmodule:function(state,{value}){

            state.sysmodule=value;
        },


        nbrepersautorise:function(state,{value}){

            state.nbrepersautorise=value;
        },


        etatlogo:function(state,{etatlogo}){

            state.etatlogo=etatlogo;
        },

        etatraisonsocial:function(state,{etatraisonsocial}){

            state.etatraisonsocial=etatraisonsocial;
        },

        etatifu:function(state,{etatifu}){

            state.etatifu=etatifu;
        },

        etatrccm:function(state,{etatrccm}){

            state.etatrccm=etatrccm;
        },

        etattelephone:function(state,{etattelephone}){

            state.etattelephone=etattelephone;
        },

        etatadresse:function(state,{etatadresse}){

            state.etatadresse=etatadresse;
        },

         userdata:function(state,{value}){

            state.userdata=value;
        },





        niveauclickmenu:function(state,{value}){

            state.niveauclickmenu=value;
        },

        myscreenSize:function(state,{value}){

            state.myscreenSize=value;
        },


        totalmoconsomme:function(state,{value}){

            state.totalmoconsomme=value;
        },


        indexbtnsuividata:function(state,{value}){

            state.indexbtnsuividata=value;
        },



        graphssuiviedata:function(state,{value}){

            state.graphssuiviedata=value;
        },




        appsociete:function(state,{name}){

            state.appsociete=name;
        },
        appserverdate:function(state,{name}){

            state.appserverdate=name;
        },

        lancerchargement:function(state,{value}){

            state.lancerchargement=value;
        },

        isAuthenticated:function(state,{value}){

            state.isAuthenticated=value;
        },

        beartoken:function(state,{value}){

            state.beartoken=value;
        },
        appusername:function(state,{name}){

            state.appusername=name;
        },
        approle:function(state,{name}){

            state.approle=name;
        },

        appuserpicture:function(state,{name}){

            state.appuserpicture=name;
        },

        appsocket:function(state,{name}){

            state.appsocket=name;
        },

        appsocketroom:function(state,{name}){

            state.appsocketroom=name;
        },

        //**************************************





        accescomponent:function(state,{value}){

            state.accescomponent=value;
        },


        authorizefacturepourgenereranalyse:function(state,{value}){

            state.authorizefacturepourgenereranalyse=value;
        },


        authorizechangementprix:function(state,{value}){

            state.authorizechangementprix=value;
        },

        authorizesignatureelectronique:function(state,{value}){

            state.authorizesignatureelectronique=value;
        },
        authorizenormalisation:function(state,{value}){

            state.authorizenormalisation=value;
        },
        authorizeprix0:function(state,{value}){

            state.authorizeprix0=value;
        },
        authorizefacturactionpartiel:function(state,{value}){

            state.authorizefacturactionpartiel=value;
        },
        totalrecette:function(state,{value}){

            state.totalrecette=value;
        },
        totaldepense:function(state,{value}){

            state.totaldepense=value;
        },
        intitulecaisse:function(state,{value}){

            state.intitulecaisse=value;
        },
        typc:function(state,{value}){

            state.typc=value;
        },

        montantencaisse:function(state,{value}){

            state.montantencaisse=value;
        },

        caisseinfo:function(state,{value}){

            state.caisseinfo=value;
        },

        infoexercicecomptable:function(state,{value}){

            state.infoexercicecomptable=value;
        },

        dateouverturecaisse:function(state,{value}){

            state.dateouverturecaisse=value;
        },

        anneexercicecomptable:function(state,{value}){

            state.anneexercicecomptable=value;
        },

        ouverturedelacaisse:function(state,{value}){

            state.ouverturedelacaisse=value;
        },

        allccessapp:function(state,{value}){

            state.allccessapp=value;
        },

        listeallcaisses:function(state,{value}){

            state.listeallcaisses=value;
        },

        listedesexercicescomptables:function(state,{value}){

            state.listedesexercicescomptables=value;
        },

        listedesarticles:function(state,{value}){

            //console.log("value//////////// ",value)

            state.listedesarticles=value;
        },

        listedesarticlespourlacaisse:function(state,{value}){

            //console.log("value//////////// ",value)

            state.listedesarticlespourlacaisse=value;
        },

        caisseencour:function(state,{value}){

            state.caisseencour=value;
        },

        exercicecomptable:function(state,{value}){

            state.exercicecomptable=value;
        },



    },
    actions:{

        niveauaccescaisse: async function (context,data) {
            context.commit('niveauaccescaisse',{niveauaccescaisse:data})

        },

        niveauaccescomptable: async function (context,data) {
            context.commit('niveauaccescomptable',{niveauaccescomptable:data})

        },


        etatlogo: async function (context,data) {

            context.commit('etatlogo',{etatlogo:data})

        },



        etatraisonsocial: async function (context,data) {

            context.commit('etatraisonsocial',{etatraisonsocial:data})

        },



        etatifu: async function (context,data) {

            context.commit('etatifu',{etatifu:data})

        },



        etatrccm: async function (context,data) {

            context.commit('etatrccm',{etatrccm:data})

        },



        etattelephone: async function (context,data) {

            context.commit('etattelephone',{etattelephone:data})

        },

        etatadresse: async function (context,data) {

            context.commit('etatadresse',{etatadresse:data})

        },

        accescomponent: async function (context,data) {

            context.commit('accescomponent',{accescomponent:data})

        },

        paramsystem:function(context,value){

            context.commit('paramsystem',{value:value});
        },

        datanotification:function(context,value){

            context.commit('datanotification',{value:value});
        },

        nbrenotification:function(context,value){

            context.commit('nbrenotification',{value:value});
        },

        paramsapp:function(context,value){

            context.commit('paramsapp',{value:value});
        },

        sysmodule:function(context,value){

            context.commit('sysmodule',{value:value});
        },

        nbrepersautorise:function(context,value){

            context.commit('nbrepersautorise',{value:value});
        },

        userdata:function(context,value){

            context.commit('userdata',{value:value});
        },




        myscreenSize:function(context,value){

            context.commit('myscreenSize',{value:value});
        },

        totalmoconsomme:function(context,value){

            context.commit('totalmoconsomme',{value:value});
        },

        indexbtnsuividata:function(context,value){

            context.commit('indexbtnsuividata',{value:value});
        },

        graphssuiviedata:function(context,value){

            context.commit('graphssuiviedata',{value:value});
        },

        niveauclickmenu:function(context,value){

            context.commit('niveauclickmenu',{value:value});
        },



        isAuthenticated:function(context,value){

            context.commit('isAuthenticated',{value:value});
        },

        beartoken:function(context,value){

            context.commit('beartoken',{value:value});
        },

        appuserpicture:function(context,value){

            context.commit('appuserpicture',{value:value});
        },

        appsocket:function(context,value){

            context.commit('appsocket',{value:value});
        },

        appsocketroom:function(context,value){

            context.commit('appsocketroom',{value:value});
        },

        appusername:function(context,value){

            context.commit('appusername',{value:value});
        },

        approle:function(context,value){

            context.commit('approle',{value:value});
        },

        lancerchargement:function(context,value){

            context.commit('lancerchargement',{value:value});
        },


/*
        isPermission:function (context,namepage){

            let mtableaudebord=[]
            mtableaudebord=context.state.allccessapp;
            let datapermissions =null;
            let mpermissions=false;

            if(mtableaudebord.length>0){

                datapermissions=mtableaudebord.find((item) => item.name === namepage );

                if(datapermissions === undefined){
                    mpermissions=false;
                }else{
                    mpermissions=datapermissions.permission;
                }


            }

            // return {mpermissions:mpermissions}
            return {mpermissions:true}

        },
*/


       listedesarticles: async function (context,value) {
            context.commit('listedesarticles',{value:value})

        },

        listedesarticlespourlacaisse: async function (context,value) {
            context.commit('listedesarticlespourlacaisse',{value:value})

        },



        loadlistedesarticlespourlacaisse: async function (context,{pidcaisseencour}) {

           let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/caisse/othetdskd8755dsdhisd/otherallproddksdk/'+pidcaisseencour+'/'+context.state.appsociete+'/'+randomString();

            let response = axios.get(url,{
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JwtService.getToken(),
                    'csrftoken': context.state.appsociete
                } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
            })
              await response
          .then(function (res) {

                    if (res.data.success){

                        context.commit('listedesarticlespourlacaisse',{value:res.data.m});
                    }else{

                        context.commit('listedesarticlespourlacaisse',{value:[]});
                    }
                })
                .catch(function(error){
                    console.log("error,",error)
                })
                .finally(function () {

                });


        },


        loadlistedesarticles: async function (context) {


            let url=process.env.VUE_APP_API_URL+'/egl745874pstock771714dfe/ar/'+context.state.appsociete+'/'+randomString();
            let response =   axios  .get(url,{
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JwtService.getToken(),
                    'csrftoken': context.state.appsociete
                } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
            })
              await response
          .then(function (res) {

                    if (res.data.success){

                      //  console.log("res.data.success ******** ",res.data.success)
                      //  console.log("res.data.m ******** ",res.data.m)

                        context.commit('listedesarticles',{value:res.data.m});
                    }else{

                        context.commit('listedesarticles',{value:[]});
                    }
                })
                .catch(function(error){
                    console.log("error,",error)
                })
                .finally(function () {

                });


        },

         loadevoluationdata: async function (context) {

            let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/dash/graph/suividata/'+context.state.indexbtnsuividata+'/'+context.state.appsociete+'/'+randomString();
            let response =   axios  .get(url,{
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JwtService.getToken(),
                    'csrftoken': context.state.appsociete
                }
                ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
            })

            await response
                .then(response => {

                    if (response.data.success){

                        context.commit('totalmoconsomme',{value:response.data.ttmo});
                        context.commit('graphssuiviedata',{value:response.data.allgraphs});


                    }
                    else{

                        context.commit('totalmoconsomme',{value:0});
                       context.commit('graphssuiviedata',{value:{}});

                    }


                })
                .catch(function(error){
                    console.log("error,",error)
                })
                .finally(function () {

                });

        },

        chektoken: async function (context,{idtoken}) {
           // context.commit('lancerchargement',{value:true});
            return new Promise( (resolve, reject) => {

                let url=context.state.baseurlapi+"/rfesh8495848484dfbfer498/"+idtoken+"/"+randomString(280)

                   axios
                    .get(url, {
                   headers: {
                       'X-Requested-With': 'XMLHttpRequest',
                       'Accept': 'application/json',
                       'Content-Type': 'application/json',
                       'Authorization': 'Bearer ' ,
                       'csrftoken': ''
                        },
                        withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                    })
                    .then(response => {

                    if(response.data.refresh){

                        context.commit('isAuthenticated',{value:true})
                        context.commit('appusername',{name:JwtService.getTokenUser()})
                        context.commit('appuserpicture',{name:response.data.datas._picture})

                        context.commit('appsociete',{name:response.data.datas._utk})
                        context.commit('appserverdate',{name:response.data.datas.dateserv})


                        context.commit('etatlogo',{etatlogo:context.state.baseurlapifile+response.data.datas.__etatinfo.etatlogo})
                        context.commit('etatraisonsocial',{etatraisonsocial:response.data.datas.__etatinfo.etatraisonsocial})
                        context.commit('etatifu',{etatifu:response.data.datas.__etatinfo.etatifu})
                        context.commit('etatrccm',{etatrccm:response.data.datas.__etatinfo.etatrccm})
                        context.commit('etattelephone',{etattelephone:response.data.datas.__etatinfo.etattelephone})
                        context.commit('etatadresse',{etatadresse:response.data.datas.__etatinfo.etatadresse})



                        context.commit('sysmodule',{value:response.data.datas.__module})
                        context.commit('paramsystem',{value:response.data.datas.__sssys})
                        context.commit('datanotification',{value:response.data.datas.__notif})
                        context.commit('nbrenotification',{value:response.data.datas.__countnotif})


                        context.commit('nbrepersautorise',{value:response.data.datas.__nbrpers})



                        //**********************************


                        context.commit('listeallcaisses',{value:response.data.datas.allcaisse})
                        context.commit('paramsapp',{value:response.data.datas.__sssysapp})
                        context.commit('accescomponent',{value:response.data.datas.iujhi7855})
                        context.commit('nbrepersautorise',{value:response.data.datas.__nbrpers})

                        context.commit('authorizechangementprix',{value:response.data.datas.__auth.authorizechangementprix})
                        context.commit('authorizesignatureelectronique',{value:response.data.datas.__auth.authorizesignatureelectronique})
                        context.commit('authorizenormalisation',{value:response.data.datas.__auth.authorizenormalisation})
                        context.commit('authorizeprix0',{value:response.data.datas.__auth.authorizeprix0})
                        context.commit('authorizefacturactionpartiel',{value:response.data.datas.__auth.authorizefacturactionpartiel})
                        context.commit('authorizefacturepourgenereranalyse',{value:response.data.datas.__auth.authorizefacturepourgenereranalyse})
                        context.commit('allccessapp',{value:response.data.datas.iujhi7855})


                        //**************************************

                        /*

                        t.socket = io(process.env.VUE_APP_API_URL);
                        // Rejoindre le canal privé
                        t.socket.emit('join_room', t.items.societe);
                        let dbdata={message:'Bonjour'}
                        t.socket.on('message', (dbdata) => {
                            t.messages.push(dbdata);
                        });

                        */

                        /*let mysocket=io(process.env.VUE_APP_API_URL_SOCKET,{
                            transports: ['websocket'], // Utilise uniquement WebSocket
                             secure: (context.state.baseurlenv==='production')?process.env.VUE_APP_API_URL.startsWith('https'):false, // Active le mode sécurisé si nécessaire
                            rejectUnauthorized: (context.state.baseurlenv === 'production'), // Utilise ceci uniquement si tu as un certificat auto-signé (à éviter en production)
                            reconnectionAttempts: 5, // Nombre de tentatives de reconnexion
                            reconnectionDelay: 1000, // Délai entre les tentatives en ms

                        });*/


                        context.commit('appsocketroom',{name: response.data.datas._sskt})

                        context.dispatch('initializeSocket').then(() => {
                           // console.log("initializeSocket a été appelé.");
                        });
                        context.dispatch('joinsocketRoom',{room:response.data.datas._sskt}).then(() => {
                           // console.log("joinsocketRoom a été appelé.");
                        });

                        context.dispatch('addDynamicListener',{event:"messageconnecte"})
                            .then(() => {
                            //console.log("res ........... ",res);
                        });





                       /* let mysocket = io(process.env.VUE_APP_API_URL_SOCKET, {
                            transports: ['websocket'], // Utilise uniquement WebSocket
                            secure: process.env.VUE_APP_API_URL.startsWith('https'), // Active le mode sécurisé si l'URL commence par 'https'
                            reconnectionAttempts: 5, // Nombre de tentatives de reconnexion
                            reconnectionDelay: 1000, // Délai entre les tentatives en ms
                            rejectUnauthorized: false // Rejet des certificats non autorisés (à éviter en production)
                        });*/




                        //context.commit('appsocket',{name: mysocket})

                       /*
                        // Initialiser le socket avec l'URL du serveur
                            const mysocket = io(process.env.VUE_APP_API_URL, {
                            transports: ['websocket'], // Utiliser uniquement WebSocket
                            secure: false, // Non sécurisé en local
                            rejectUnauthorized: false // À utiliser uniquement si nécessaire
                        });

                        */


/*

                        mysocket.emit('join_room', response.data.datas._sskt);
                        // Log pour les messages reçus
                        mysocket.on('messageconnecte', (msg) => {
                            console.log("Message reçu du serveur 1111 :", msg);
                        });
                        mysocket.emit('message', 'Hello Server!');

                        */

                       /*
                        mysocket.onAny((event, ...args) => {
                            console.log(`Événement reçu: ${event}`, args);
                        });
                        */

                       /* mysocket.on('connect', () => {
                            console.log('Connecté au serveur WebSocket avec l\'ID :', mysocket.id);
                        });

                        // Log lors de la déconnexion
                        mysocket.on('disconnect', (reason) => {
                            console.log('Déconnecté du serveur WebSocket. Raison:', reason);
                        });

                            // Log lors de la reconnexion
                        mysocket.on('reconnect', (attemptNumber) => {
                            console.log('Reconnexion au serveur WebSocket après', attemptNumber, 'tentatives');
                        });

                                    // Log lors d'une tentative de reconnexion échouée
                        mysocket.on('reconnect_failed', () => {
                            console.log('Échec de la reconnexion au serveur WebSocket');
                        });

                        // Log pour les messages reçus
                        mysocket.on('message', (msg) => {
                            console.log("Message reçu du serveur:", msg);
                        });*/



                        context.dispatch('loadevoluationdata')
                        context.dispatch('loadlistedesarticles')

                    }
                    else{

                        //console.log("not found...")

                        JwtService.unsetTokenRole();
                        JwtService.unsetToken();
                       JwtService.unsetTokenEXPIRESIN();
                        JwtService.unsetTokenRole();
                        JwtService.unsetTokenID();
                        JwtService.unsetTokenUser();
                    }

                  return   resolve(response);
                }, error => {
                    // http failed, let the calling function know that action did not work out
                        return  reject(error);
                })
            })


        },

        // Initialisation du socket
        initializeSocket() {
            //socketService.initializeSocket('http://localhost:3000');
            socketService.initializeSocket();

            socketService.onMessage((msg) => {
                console.log('Message reçu:', msg);
            });

            /*socketService.onPrivateMessage((msg) => {
                console.log('Message privé reçu:', msg);
                const { room, message, from } = msg;

                if (!state.privateMessages[room]) {
                    state.privateMessages[room] = [];
                }

                state.privateMessages[room].push({ id: Date.now(), content: message, from, received: true });
            });*/
        },

        sendsocketMessage(context,{event, message} ) {
            socketService.sendMessage(event, message);
        },
        // Gestion des rooms
        joinsocketRoom(context,{room}) {

                     if (!state.rooms.includes(room)) {
                state.rooms.push(room);
                state.privateMessages[room] = [];
                socketService.joinRoom(room);
            }
        },

        leavesocketRoom(context,{room}) {
            const index = state.rooms.indexOf(room);
            if (index !== -1) {
                state.rooms.splice(index, 1);
                delete state.privateMessages[room];
                socketService.leaveRoom(room);
            }
        },

        sendsocketPrivateMessage(context,{room, message} ) {
            if (state.rooms.includes(room)) {
                socketService.sendPrivateMessage(room, message);
             //   state.privateMessages[room].push({ id: Date.now(), content: message, from: state.user.id, received: false });
            }
            else {
                console.error(`Vous n'êtes pas connecté à la room ${room}`);
            }
        },

        addDynamicListener(context,{event}) {

            return socketService.onListener(event); // Retourner la promesse
        },


//***************************************************************

        authorizefacturepourgenereranalyse: async function (context,data) {

            context.commit('authorizefacturepourgenereranalyse',{value:data})

        },

        authorizechangementprix: async function (context,data) {

            context.commit('authorizechangementprix',{value:data})

        },
        authorizesignatureelectronique: async function (context,data) {

            context.commit('authorizesignatureelectronique',{value:data})

        },
        authorizenormalisation: async function (context,data) {
            context.commit('authorizenormalisation',{value:data})

        },
        authorizeprix0: async function (context,data) {
            context.commit('authorizeprix0',{value:data})

        },
        authorizefacturactionpartiel: async function (context,data) {
            context.commit('authorizefacturactionpartiel',{value:data})

        },
        typc: async function (context,data) {
            context.commit('typc',{value:data})

        },
        totalrecette: async function (context,data) {
            context.commit('totalrecette',{value:data})

        },
        totaldepense: async function (context,data) {
            context.commit('totaldepense',{value:data})

        },


        intitulecaisse: async function (context,data) {
            context.commit('intitulecaisse',{value:data})

        },

        isChangecaisseaccess:function (context,idcaisse){

            var mtableaudebord = [];
            mtableaudebord=context.state.listeallcaisses.slice();

            for (var i=0; i<mtableaudebord.length; i++) {

                var obj = Object.assign({}, mtableaudebord[i]);

                if (obj.pid === idcaisse) {

                    if(obj.opencaisse === 1)
                    {
                        obj.opencaisse=0
                    }
                    else{
                        obj.opencaisse=1
                    }

                }
                mtableaudebord[i] = obj;
            }

            context.commit('listeallcaisses',{value:mtableaudebord})


        },

        /*

        isPermission:function (context,namepage){

            let mtableaudebord=[]
            mtableaudebord=context.state.allccessapp;
            let datapermissions =null;
            let mpermissions=false;

            if(mtableaudebord.length>0){

                if (namepage==="login" ||  namepage==="dashbord"){

                    mpermissions=true;


                }else{

                    datapermissions=mtableaudebord.find((item) => item.name === namepage );
                    if(datapermissions === undefined){
                        mpermissions=false;
                    }else{
                        mpermissions=datapermissions.permission;
                    }


                }




            }else{


                if (namepage==="login" ||  namepage==="dashbord"){

                    mpermissions=true;

                }


            }

//             return {mpermissions:mpermissions}
            return {mpermissions:true}

        },


        */


        caisseinfo: async function (context,data) {
            context.commit('caisseinfo',{value:data})

        },

        infoexercicecomptable: async function (context,data) {
            context.commit('infoexercicecomptable',{value:data})

        },

        montantencaisse: async function (context,data) {
            context.commit('montantencaisse',{value:data})

        },

        dateouverturecaisse: async function (context,data) {
            context.commit('dateouverturecaisse',{value:data})

        },

        anneexercicecomptable: async function (context,data) {
            context.commit('anneexercicecomptable',{value:data})

        },

        caisseencour: async function (context,data) {
            context.commit('caisseencour',{value:data})

        },

        exercicecomptable: async function (context,data) {
            context.commit('exercicecomptable',{value:data})

        },

        ouverturedelacaisse: async function (context,data) {
            context.commit('ouverturedelacaisse',{value:data})

        },

        listeallcaisses: async function (context,data) {
            context.commit('listeallcaisses',{value:data})

        },

        listedesexercicescomptables: async function (context,data) {
            context.commit('listedesexercicescomptables',{value:data})

        },


        allccessapp: async function (context,data) {
            context.commit('allccessapp',{value:data})

        },



    },
});

